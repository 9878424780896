@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
    @apply font-syne;
    @apply font-bold;
  }
  h2 {
    @apply text-xl;
  }
  p {
    @apply font-space;
  }
  .field-label {
    @apply block text-sm font-semibold text-gray-700;
  }
  .field-text {
    @apply p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-200 rounded-md;
  }
  .btn-submit {
    @apply relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }
  .btn-reset {
    @apply relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md text-white bg-gray-300 shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500;
  }
}
